<template>
    <div @click.stop="openFile(Mitgliederliste.file)" class="mt-2 mb-2 kath-sz-card link">
        {{Mitgliederliste.name}}
    </div>
</template>

<script>
    export default {
        name: "Kantonskirchenrat_Verzeichnis_der_Mitglieder",
        methods: {
            openFile(filename){
                window.open( filename, '_blank');
            },
        },
        computed: {
            locations () {
                return this.$store.getters.registeredMembers
            },
            Mitgliederliste () {
                return this.$store.getters.MitgliederKantonskirchenrates[0]
            }
        }
    }
</script>

<style scoped>

</style>