<template>
    <div class="kirchen-vorstand-wrapper">
        <div v-for="(member, i) in VorstandMembers" :key="i"
                @click.stop="mailTo(member.eMail)"
                class="link link-card">
            <div @click.stop="mailTo(member.eMail)"
                    class="kath-sz-card"
            >
                <v-img :src="member.foto"
                       class="kath-sz-card-image"
                       max-height="150"
                       contain
                >

                </v-img>
                <div class="kath-sz-card-header">
                    {{member.Name}} <br>
                    {{member.funktion}}
                </div>
                <div class="text-truncate kath-sz-card-additional-info">
                    {{member.adresse.Strasse}} {{member.adresse.Nr}} <br>
                    {{member.adresse.PLZ}} {{member.adresse.Ort}}<br>
                    {{member.eMail}}<br>
                    {{member.tel}}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "KirchenVostand",
        data(){
            return {
                VorstandMembers: this.$store.getters.KirchenvorstandMember
            }
        },
        methods: {
            mailTo(email) {
                window.open('mailto:' + email, '_blank');
            },
        },
    }
</script>

<style scoped>

</style>