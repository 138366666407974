<template>
    <div>
        <div class="mb-2 hidden-md-and-down link"
             @click.stop="openFile(Kommissionen.file)"
        >
            <div class="kath-sz-card">
                    {{Kommissionen.name}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Kantonskirchenrat_Geschaeftsprufngskommission",
        methods: {
            openFile(filename){
                window.open( filename, '_blank');
            },
        },
        computed: {
            Kommissionen () {
                return this.$store.getters.KommissionenKantonskirchenrates[0]
            }
        }
    }
</script>

<style scoped>

</style>