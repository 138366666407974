<template>
    <div class="full-width pl-5 pr-5">
        <div class="text-xs-center mb-5">
            <h1 class="sz-kath-title">{{title.value}}</h1>
        </div>
        <KirchenvostandComp></KirchenvostandComp>
        <!--
        ---------------------------------------------------------------------------------------------------------------
        both
        ---------------------------------------------------------------------------------------------------------------
        -->
        <div class="pt-5 text-xs-center ">
            <RechenschaftsberichteComp class="subpage-wrapper-small"></RechenschaftsberichteComp>
        </div>
    </div>
    
</template>

<script>
    import KirchenvostandComp from '../components/KirchenVostand.vue';
    import RechenschaftsberichteComp from '../components/Rechenschaftsberichte.vue'

    export default {
        name: "Kirchenvorstand",
        components: {
            KirchenvostandComp,
            RechenschaftsberichteComp,
        },
        computed: {
            title () {
                return this.$store.getters.texts.filter(text => text.key === 'Kirchenvorstand')[0]
            }
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToSmall');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>

</style>