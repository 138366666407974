<template>
    <div class="mb-2 mt-2 kath-sz-card">
        {{buero.presidentTitle}} <br>
        <b @click.stop="mailTo(buero.currentPresident.mail)" class="link">{{buero.currentPresident.name}}</b>
        <br>{{buero.currentPresident.streetName}} {{buero.currentPresident.streetNumber}} <br>
        {{buero.currentPresident.cityCode}} {{buero.currentPresident.cityName}}
        <br>
        <span  @click.stop="mailTo(buero.currentPresident.mail)" class="link">{{buero.currentPresident.mail}}</span>
        <br>
        <br>
        {{buero.vicePresidentTitle}} <br>
        <b>{{buero.currentVicePresident}}</b>
        <br>
        <br>
        {{buero.voiceCountersTitle}} <br>
        <b v-for="voiceCounter in buero.currentVoiceCounters"
           :key="voiceCounter"
        >
            {{voiceCounter}} <br>
        </b>
    </div>
</template>

<script>
    export default {
        name: "Kantonskirchenrat_Buero",
        computed: {
            buero () {
                let buero = this.$store.getters.texts.filter(text => text.target === '/Organisation/Kantonskirchenrat');

                return {
                    presidentTitle: buero.filter(text => text.name === 'presidentTitle')[0].value,
                    currentPresident: {
                        name: buero.filter(text => text.name === 'currentPresident')[0].currentPresident.name,
                        mail: buero.filter(text => text.name === 'currentPresident')[0].currentPresident.mail,
                        streetName: buero.filter(text => text.name === 'currentPresident')[0].currentPresident.streetName,
                        streetNumber: buero.filter(text => text.name === 'currentPresident')[0].currentPresident.streetNumber,
                        cityCode: buero.filter(text => text.name === 'currentPresident')[0].currentPresident.cityCode,
                        cityName: buero.filter(text => text.name === 'currentPresident')[0].currentPresident.cityName,
                    },
                    vicePresidentTitle: buero.filter(text => text.name === 'vicePresidentTitle')[0].value,
                    currentVicePresident: buero.filter(text => text.name === 'currentVicePresident')[0].value,
                    voiceCountersTitle: buero.filter(text => text.name === 'voiceCountersTitle')[0].value,
                    currentVoiceCounters: buero.filter(text => text.name === 'currentVoiceCounters')[0]
                        .currentVoiceCounters
                }
            }
        },
        methods: {
            mailTo (email) {
                window.open('mailto:' + email, '_blank');
            }
        }
    }
</script>

<style scoped>

</style>