<template>
  <div class="organisations-wrapper full-width subpage-wrapper-small">
    <sekretariat class="mb-2 subpage-content"></sekretariat>
    <kantonskirchenrat class="subpage-content">
    </kantonskirchenrat>
    <kirchen-vostand class="full-width mt-2"></kirchen-vostand>
    <rekurskommission class="subpage-content"></rekurskommission>
  </div>
</template>

<script>
  import Sekretariat from "../views/Sekretariat";
  import Kantonskirchenrat from "../views/Kantonskirchenrat";
  import KirchenVostand from "../views/Kirchenvorstand";
  import Rekurskommission from "../views/Rekurskommission";
export default {
  name: 'Organisation',
  components: {
    Sekretariat,
    Kantonskirchenrat,
    KirchenVostand,
    Rekurskommission
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
