<template>
    <div>
        <div class="text-xs-center">
            <h1 class="sz-kath-title">Verzeichnis der Mitglieder</h1>
        </div>
        <div>
            <VerzeichnisDerMitglieder></VerzeichnisDerMitglieder>
        </div>
        <div class="text-xs-center">
            <h1 class="sz-kath-title">Büro des Kantonskirchenrates</h1>
        </div>
        <div >
            <Buero></Buero>
        </div>
        <div  class="text-xs-center">
            <h1 class="sz-kath-title">Kommissionen</h1>
        </div>
        <div >
            <Geschaeftsprufngskommission></Geschaeftsprufngskommission>
        </div>
        <div  class="text-xs-center">
            <h1 class="sz-kath-title">Protokolle</h1>
        </div>
        <div >
            <Protokolle></Protokolle>
        </div>
    </div>
    
</template>

<script>
    import Buero from '../components/Kantonskirchenrat/Buero.vue'
    import Kommissionen from '../components/Kantonskirchenrat/Kommissionen.vue'
    import VerzeichnisDerMitglieder from '../components/Kantonskirchenrat/Verzeichnis_der_Mitglieder.vue'
    import Protokolle from '../components/Kantonskirchenrat/Protokolle.vue'

    export default {
        name: "Kantonskirchenrat",
        components: {
            Buero,
            Geschaeftsprufngskommission: Kommissionen,
            VerzeichnisDerMitglieder,
            Protokolle,
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToSmall');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>



<style scoped>

</style>