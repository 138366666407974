<template>
    <div @click.stop="mailTo('sekretariat@sz.kath.ch')"
            class="text-xs-center pa-4 kath-sz-card">
            <v-img src="/img/Linus-Bruhin.jpeg"
                   contain
                   max-height="350"
                   class="kath-sz-card-image"
            ></v-img>
            <div class="sz-kath-title">
                <p class="subheading">
                    {{langs['title']}}
                </p>
            </div>
            <div v-html="langs['value']" class="text-xs-left pl-5 kath-sz-card-additional-info">
            </div>
    </div>
</template>

<script>
    export default {
        name: "SekretariatComp",
        methods: {
            mailTo (email) {
                window.open('mailto:' + email, '_blank');
            }
        },
        computed: {
            langs () {
                return this.$store.getters.Sekretariat
            }
        }
    }
</script>

<style scoped>

</style>