<template>
    <div>
        <div class="text-xs-center" >
            <h1 class="sz-kath-title">
                Sekretariat
            </h1>
        </div>
        <div>
            <SekretariatComp></SekretariatComp>
        </div>
    </div>

</template>

<script>
    import SekretariatComp from '../components/Sekretariat.vue'
    export default {
        name: "Sekretariat",
        components: {
            SekretariatComp,
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToSmall');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>

</style>