<template>
    <rekurskommissionComp></rekurskommissionComp>
</template>

<script>
    import rekurskommissionComp from '../components/Rekurskommission.vue'
    export default {
        name: "Rekurskomission",
        components: {
            rekurskommissionComp
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToSmall');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>

</style>