<template>
    <div>
        <div class="text-xs-center">
            <h1 class="sz-kath-title">{{langs[0]['name']}}</h1>
        </div>
        <div class="one-column-wrapper">
            <div v-for="( lang, key) in langs" :key="key"
                 class="text-xs-center pa-3 kath-sz-card ">
                <div class="">
                    <p class="subheading font-weight-bold">
                        {{lang['title']}}
                    </p>
                </div>
                <div v-html="lang['value']" class="text-xs-left kath-sz-card-additional-info"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RekurskommissionComp",
        computed: {
            langs () {
                return this.$store.getters.Rekurskommission
            }
        }
    }
</script>

<style scoped>

</style>